
import ExternalAnalytics from '@/components/external/ExternalAnalytics.vue'
import ApiError from '@/components/snackbars/ApiError.vue'
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import * as log from '@/utils/log'
import { segmentEventTracking, segmentIdentityTracking } from '@/utils/tracking'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
declare var consoleLog: any

@Component({
  metaInfo: {
    title: 'JigSpace team dashboard',
    titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
  },
  components: {
    ApiError,
    'external-analytics': ExternalAnalytics,
  },
})
export default class App extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('tenantUserCount', { namespace: Namespace.App })
  public tenantUserCount!: number

  @Action('updateMyMemberTenants', { namespace: Namespace.App })
  public updateMyMemberTenants: any

  private isInitialIdentifyFired: boolean = false

  protected async created() {
    try {
      Vue.prototype.$log = log
    } catch {
      // Suppress the 'not logged in' error as we can illegitimately get that
      // when processing the callback url
    }
  }

  protected async mounted() {
    await this.$auth0.isReady()

    if (this.$auth0.tenants) {
      this.updateMyMemberTenants(this.$auth0.tenants)
    }
  }

  // On very initial page load, myTenants state which contains Tenant Names are not ready after App has finished the mount.
  // So we need to send segment identify event here in order to get Tenant Names.
  // But we don't want to fire it every time there are chagnes in myTenants value, so add a check to only do so on initial chagne.
  // Also triggering in Watch function is more accurate compare to mounted hook. `auth0.isAuthenticated` may not be ready in mounted hook,
  // but it is definitely ready when tenantUserCount state value is set.
  @Watch('tenantUserCount')
  private async onTenantUserCountChanged(value: number) {
    if (this.$auth0.isAuthenticated && !this.isInitialIdentifyFired) {
      this.isInitialIdentifyFired = await segmentIdentityTracking(this.myTenants, this.myTenant, { TenantUserCount: value })

      segmentEventTracking('UserLoggedIn', {
        Email: this.$auth0.user.email,
        jigUserId: this.$auth0.uid,
        TenantID: this.myTenant.ID,
        TenantName: this.myTenant.Name,
        TenantUserCount: this.tenantUserCount,
        Subscription: AppHelper.getTierType(this.myTenant.Subscription.TierType),
      })
    }
  }

  private get isAtCallback(): boolean {
    // Your computed property logic here
    return this.$route.path === '/callback'
  }

  private get theme(): string {
    if (this.$route.path === '/pre-login') {
      return 'pre-login'
    } else if (this.$route.path === '/welcome') {
      return 'welcome'
    } else {
      return 'default'
    }
  }

  async triggerIdentify(callback: Function) {
    await segmentIdentityTracking(this.myTenants, this.myTenant, { TenantUserCount: this.tenantUserCount })

    consoleLog('Identity event on logout triggered')

    if (typeof callback === 'function') {
      callback()
    }
  }
}
